
import './BgConstructorAddFloorOrWall.css';

import { defineComponent, PropType } from 'vue-demi';
import Button from 'primevue/button';
import { Stores, useStores } from '@/store/Stores';
import { computed } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { UploadedFile } from '../BgConstructor';
import { BgConstructorStore, BgConstructorStoreState } from '../BgConstructorStore';

export default defineComponent({
  props: {
    store: Object as PropType<BgConstructorStore>,
    state: Object as PropType<BgConstructorStoreState>,

    target: String as PropType<'wall' | 'floor'>,
    files: Object as PropType<UploadedFile[]>
  },
  components: {
    Button
  },
  emits: ['update:target', 'update:files', 'uploadFinished'],
  data() {
    return {
      areSelectedFileUploading: false,

      uploadedFilesTarget: this.target,
      uploadedFiles: this.files,

      name: '',

      areAllFilesAdded: !this.files.filter((s) => !s.file).length
    };
  },
  methods: {
    cancelUploadingFiles() {
      this.uploadedFiles = [];
      this.$emit('update:files', []);
    },

    replaceUploadedFile(uploadFile: UploadedFile, e: Event) {
      uploadFile.file = (e.target as any).files[0];
      uploadFile.filePreview = uploadFile.file ? URL.createObjectURL(uploadFile.file) : (null as string);

      this.areAllFilesAdded = !this.uploadedFiles.filter((s) => !s.file).length;
    },

    async uploadSelectedFiles() {
      this.areSelectedFileUploading = true;

      const dataTransfer = new DataTransfer();
      const files: File[] = [];

      for (let file of this.uploadedFiles) {
        files.push(file.file);
        dataTransfer.items.add(new File([file.file], file.name));
      }

      await this.store.uploadWallOrFloorImages(this.uploadedFilesTarget, this.name, files);

      this.areSelectedFileUploading = false;

      this.uploadedFiles = [];
      this.$emit('uploadFinished');
    }
  },
  setup() {
    const toast = useToast();
    const { t } = useI18n();

    return {
      toast,
      t
    };
  }
});
