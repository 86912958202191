import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "sceneConstructorContents" }
const _hoisted_2 = {
  ref: "contentsWrap",
  class: "sceneConstructorContents__wrap"
}
const _hoisted_3 = {
  key: 0,
  class: "sceneConstructorContents__buttons"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_image = _resolveComponent("v-image")!
  const _component_v_group = _resolveComponent("v-group")!
  const _component_v_transformer = _resolveComponent("v-transformer")!
  const _component_v_layer = _resolveComponent("v-layer")!
  const _component_v_stage = _resolveComponent("v-stage")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        ref: "contentsIn",
        class: "sceneConstructorContents__wrapIn",
        style: _normalizeStyle({
          'width': `${_ctx.state.configKonva.width}px`,
          'min-width': `${_ctx.state.configKonva.width}px`,
          'max-width': `${_ctx.state.configKonva.width}px`,

          'height': `${_ctx.state.configKonva.height}px`,
          'min-height': `${_ctx.state.configKonva.height}px`,
          'max-height': `${_ctx.state.configKonva.height}px`,

          'background': _ctx.state.backgroundColor
        })
      }, [
        _createVNode(_component_v_stage, {
          config: _ctx.state.configKonva,
          onMousedown: _ctx.handleStageMouseDown,
          onTouchstart: _ctx.handleStageMouseDown
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_layer, null, {
              default: _withCtx(() => [
                (_ctx.state.configBackground)
                  ? (_openBlock(), _createBlock(_component_v_image, {
                      key: 0,
                      ref: "backgroundImage",
                      config: _ctx.state.configBackground
                    }, null, 8, ["config"]))
                  : _createCommentVNode("", true),
                (_ctx.state.configConstructedBackground)
                  ? (_openBlock(), _createBlock(_component_v_group, {
                      key: 1,
                      ref: "constructedBackgroundImage",
                      config: _ctx.state.configConstructedBackground
                    }, {
                      default: _withCtx(() => [
                        (_ctx.state.configFloor)
                          ? (_openBlock(), _createBlock(_component_v_image, {
                              key: 0,
                              config: _ctx.state.configFloor
                            }, null, 8, ["config"]))
                          : _createCommentVNode("", true),
                        (_ctx.state.configWall)
                          ? (_openBlock(), _createBlock(_component_v_image, {
                              key: 1,
                              config: _ctx.state.configWall
                            }, null, 8, ["config"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["config"]))
                  : _createCommentVNode("", true),
                (_ctx.state.configLogo)
                  ? (_openBlock(), _createBlock(_component_v_image, {
                      key: 2,
                      ref: "logoImage",
                      config: _ctx.state.configLogo
                    }, null, 8, ["config"]))
                  : _createCommentVNode("", true),
                (_ctx.state.configBanner)
                  ? (_openBlock(), _createBlock(_component_v_image, {
                      key: 3,
                      ref: "bannerImage",
                      config: _ctx.state.configBanner
                    }, null, 8, ["config"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_v_transformer, { ref: "transformer" }, null, 512)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["config", "onMousedown", "onTouchstart"])
      ], 4),
      (_ctx.state.activeView == 'start')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_Button, {
              onClick: _ctx.saveChanges,
              class: "p-button p-component",
              disabled: _ctx.state.isSaving
            }, {
              default: _withCtx(() => [
                (!_ctx.state.isSaving)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('scene_constructor.save')), 1))
                  : _createCommentVNode("", true),
                (_ctx.state.isSaving)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('scene_constructor.saving')), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["onClick", "disabled"]),
            _createVNode(_component_Button, {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
              class: "p-button p-component p-button-raised p-button-secondary p-button-text"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('scene_constructor.cancel')), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ], 512)
  ]))
}