
import './SceneConstructorMenu.css';

import { defineComponent, PropType } from 'vue-demi';

export default defineComponent({
  props: {
    showBackButton: Boolean,
    showArrows: Boolean
  },
  emits: ['goBack'],
  methods: {
    scrollTop() {
      const scrollable: HTMLDivElement = this.$refs.scrollable as any;

      if (scrollable) {
        scrollable.scrollTo({ top: scrollable.scrollTop - 100, behavior: 'smooth' });
      }
    },
    scrollBottom() {
      const scrollable: HTMLDivElement = this.$refs.scrollable as any;

      if (scrollable) {
        scrollable.scrollTo({ top: scrollable.scrollTop + 100, behavior: 'smooth' });
      }
    }
  }
});
