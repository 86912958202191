
import './BgConstructorFloors.css';

import { defineComponent, PropType } from 'vue-demi';
import { useStores } from '@/store/Stores';
import { RoomComponent } from '@/store/modules/RoomComponentsStore';
import { BgConstructorStore, BgConstructorStoreState } from '../BgConstructorStore';

export default defineComponent({
  props: {
    store: Object as PropType<BgConstructorStore>,
    state: Object as PropType<BgConstructorStoreState>
  },
  methods: {
    selectFloor(floor: RoomComponent) {
      this.store.dispatch(async (state) => {
        state.selectedFloor = floor;
      });
    },

    selectFloorAt(floorIndex: number) {
      if (-1 < floorIndex && floorIndex < this.state.floors.length) {
        this.selectFloor(this.state.floors[floorIndex]);

        setTimeout(() => {
          document.querySelector('ul.bg-constructor-floors__list').children[floorIndex].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest'
          });
        }, 50);
      }
    },

    selectNextFloor() {
      let floorIndex = this.state.floors.indexOf(this.state.selectedFloor as any);

      this.selectFloorAt(floorIndex + 1);
    },

    selectPreviousFloor() {
      let floorIndex = this.state.floors.indexOf(this.state.selectedFloor as any);

      this.selectFloorAt(floorIndex - 1);
    }
  }
});
