
import './BgConstructorPreview.css';

import { defineComponent } from 'vue-demi';
import Button from 'primevue/button';
import { Stores, useStores } from '@/store/Stores';
import { computed, PropType } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import { UploadedFile } from '../BgConstructor';
import BgConstructorAddFloorOrWall from '../BgConstructorAddFloorOrWall/BgConstructorAddFloorOrWall.vue';
import BgConstructorImportExport from '../BgConstructorImportExport/BgConstructorImportExport.vue';
import { BgConstructorStore, BgConstructorStoreState } from '../BgConstructorStore';

export default defineComponent({
  props: {
    store: Object as PropType<BgConstructorStore>,
    state: Object as PropType<BgConstructorStoreState>
  },
  components: {
    Button,
    BgConstructorAddFloorOrWall,
    BgConstructorImportExport
  },
  emits: ['saved', 'close'],
  data() {
    return {
      isDownloadPopupShown: false,
      isUploadPopupShown: false,

      uploadedFilesTarget: null as 'wall' | 'floor',
      uploadedFiles: [] as UploadedFile[]
    };
  },
  methods: {
    downloadWallOrFloorImages(target: 'wall' | 'floor') {
      this.isDownloadPopupShown = false;
      this.store.downloadWallOrFloorImages(target);
    },

    uploadWallOrFloorImages(target: 'wall' | 'floor', e: Event) {
      this.isUploadPopupShown = false;

      const uploadedFiles = [] as UploadedFile[];

      const sourceFiles = (e.target as HTMLInputElement).files;
      const files: File[] = [];

      for (let i = 0; i < sourceFiles.length; i++) {
        const file = sourceFiles[i];

        files.push(file);
      }

      for (let i = 1; i < 13; i++) {
        const filename = target == 'wall' ? `${i}w.png` : `${i}f.png`;
        const file = files.filter((s) => s.name == filename)[0];
        const filePreview = file ? URL.createObjectURL(file) : (null as string);

        uploadedFiles.push({
          name: filename,
          file: file,
          filePreview: filePreview
        });
      }

      this.uploadedFilesTarget = target;
      this.uploadedFiles = uploadedFiles;
    },

    toggleDownloadPopup() {
      this.isDownloadPopupShown = !this.isDownloadPopupShown;
    },

    toggleUploadPopup() {
      this.isUploadPopupShown = !this.isUploadPopupShown;
    },

    createRoom() {
      if (!this.state || !this.state.selectedFloor || !this.state.selectedWall) {
        return;
      }

      this.$emit('saved');
    }
  },
  setup() {
    const toast = useToast();
    const { t } = useI18n();

    return {
      toast,
      t
    };
  }
});
