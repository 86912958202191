import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "bg-constructor__body"
}
const _hoisted_2 = { class: "bg-constructor__left" }
const _hoisted_3 = { class: "bg-constructor__contents" }
const _hoisted_4 = { class: "bg-constructor__bottom" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")!
  const _component_BgConstructorWalls = _resolveComponent("BgConstructorWalls")!
  const _component_BgConstructorPreview = _resolveComponent("BgConstructorPreview")!
  const _component_BgConstructorFloors = _resolveComponent("BgConstructorFloors")!
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    class: "bg-constructor",
    visible: _ctx.$props.isShown,
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$props.isShown) = $event)),
    modal: true,
    draggable: false
  }, {
    default: _withCtx(() => [
      (_ctx.state.isLoading)
        ? (_openBlock(), _createBlock(_component_Loader, {
            key: 0,
            isOverlay: ""
          }))
        : _createCommentVNode("", true),
      (!_ctx.state.isLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_BgConstructorWalls, {
                store: _ctx.store,
                state: _ctx.state
              }, null, 8, ["store", "state"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_BgConstructorPreview, {
                store: _ctx.store,
                state: _ctx.state,
                onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:isShown'))),
                onSaved: _cache[1] || (_cache[1] = () => _ctx.onSave())
              }, null, 8, ["store", "state"])
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_BgConstructorFloors, {
                store: _ctx.store,
                state: _ctx.state
              }, null, 8, ["store", "state"])
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_Button, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:isShown'))),
        class: "bg-constructor__close p-button-outlined p-button-gray p-button-rounded p-button-only-icon"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Icon, { name: "close" })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible"]))
}