import { Stores } from '@/store/Stores';
import { RoomComponent } from '../../../store/modules/RoomComponentsStore';
import API from '../../../api';
import { Logo } from '@/layouts/components';

export const ConstructorStageWidth = 1920;
export const ConstructorStageHeight = 1440;

export type ConstructorView = 'start' | 'bgMenu' | 'bgColor' | 'ownBackgrounds' | 'constructedBackgrounds' | 'logos' | 'banners';
export type ConstructorTransformTarget = 'none' | 'background' | 'constructedBackground' | 'logo' | 'banner' | 'wall' | 'floor';

export interface SceneConstructorBackground {
  base_url: string;
  company_id: number;
  has_room: boolean;
  id: number;
  photo_box_url: string;
}

export interface SceneConstructorLogo {
  company_id: number;
  id: number;
  url: string;
}

export interface SceneConstructorBanner {
  company_id: number;
  id: number;
  url: string;
}

export interface SceneConstructorStoreState {
  adminMode?: boolean;
  companyId?: number;

  isLoading: boolean;
  isError?: boolean;
  isProcessing?: boolean;
  isSaving?: boolean;

  bgConstructor?: () => any;

  error?: any;

  activeView: ConstructorView;

  baseOrPhotobox: 'base' | 'photo_box';

  selectedFloor?: RoomComponent;
  selectedWall?: RoomComponent;

  ownBackgrounds: SceneConstructorBackground[];
  constructedBackgrounds: SceneConstructorBackground[];

  selectedBackground?: SceneConstructorBackground;

  logos: SceneConstructorLogo[];
  selectedLogo?: SceneConstructorLogo;

  banners: SceneConstructorBanner[];
  selectedBanner?: SceneConstructorBanner;

  selectedTransformer: ConstructorTransformTarget;

  backgroundColor: string;

  configBackground?: {
    image: any;
    x: number;
    y: number;
    width: number;
    height: number;
    name: ConstructorTransformTarget;
    scaleX: number;
    scaleY: number;
    draggable: boolean;
  };

  configConstructedBackground?: {
    x: number;
    y: number;
    width: number;
    height: number;
    name: ConstructorTransformTarget;
    scaleX: number;
    scaleY: number;
    draggable: boolean;
  };

  configWall?: {
    image: any;
    x: number;
    y: number;
    width: number;
    height: number;
  };

  configFloor?: {
    image: any;
    x: number;
    y: number;
    width: number;
    height: number;
  };

  configLogo?: {
    image: any;
    x: number;
    y: number;
    width: number;
    height: number;
    name: ConstructorTransformTarget;
    scaleX: number;
    scaleY: number;
    draggable: boolean;
  };

  configBanner?: {
    image: any;
    x: number;
    y: number;
    width: number;
    height: number;
    name: ConstructorTransformTarget;
    scaleX: number;
    scaleY: number;
    draggable: boolean;
  };

  configKonva: {
    width: number;
    height: number;
    scaleX: number;
    scaleY: number;
  };
}

export class SceneConstructorStore {
  public namespace: string;

  constructor(namespace: string) {
    this.namespace = namespace;
  }

  public get state(): SceneConstructorStoreState {
    return Stores.rootStore.state[this.namespace];
  }

  public dispatch<T>(doUpdate: (state: SceneConstructorStoreState) => Promise<T>): Promise<T> {
    return Stores.rootStore.dispatch(`${this.namespace}/updateState`, { doUpdate });
  }

  public static register() {
    const namespace = `sceneConstructor_${Date.now()}`;

    Stores.rootStore.registerModule(namespace, {
      namespaced: true,

      state: (): SceneConstructorStoreState => ({
        isLoading: true,
        activeView: 'start',
        baseOrPhotobox: 'base',

        selectedTransformer: 'none',

        ownBackgrounds: [],
        constructedBackgrounds: [],

        backgroundColor: '#e4e4e4',

        logos: [],
        banners: [],

        configKonva: {
          width: ConstructorStageWidth,
          height: ConstructorStageHeight,
          scaleX: 1,
          scaleY: 1
        }
      }),

      actions: {
        async updateState({ state }: { state: SceneConstructorStoreState }, args: { doUpdate: (state: SceneConstructorStoreState) => Promise<any> }) {
          return await args.doUpdate(state);
        }
      }
    });

    return new SceneConstructorStore(namespace);
  }

  public unregister() {
    Stores.rootStore.unregisterModule(this.namespace);
  }

  public async init(adminMode: boolean = false, companyId: number = null, bgConstructor: () => any): Promise<void> {
    this.dispatch(async (state) => {
      state.adminMode = adminMode;
      state.companyId = companyId;

      state.isLoading = true;
      state.bgConstructor = bgConstructor;

      try {
        if (state.adminMode) {
          const backgroundsResponse = await API.get(`admin/companies/${state.companyId}/background`);
          const backgrounds: SceneConstructorBackground[] = backgroundsResponse.data;

          state.ownBackgrounds = backgrounds.filter((s) => !s.has_room);
          state.constructedBackgrounds = backgrounds.filter((s) => s.has_room);

          const logos = await API.get(`admin/companies/${state.companyId}/logo`);
          state.logos = logos.data;

          const banners = await API.get(`admin/companies/${state.companyId}/banner`);
          state.banners = banners.data;
        } else {
          const backgroundsResponse = await API.get('company/background');
          const backgrounds: SceneConstructorBackground[] = backgroundsResponse.data;

          state.ownBackgrounds = backgrounds.filter((s) => !s.has_room);
          state.constructedBackgrounds = backgrounds.filter((s) => s.has_room);

          const logos = await API.get('company/logo');
          state.logos = logos.data;

          const banners = await API.get('company/banner');
          state.banners = banners.data;
        }

        state.isLoading = false;
        state.isError = false;
      } catch (error) {
        state.isLoading = false;
        state.isError = true;
        state.error = error;
      }
    });
  }

  public goTo(view: ConstructorView) {
    this.dispatch(async (state) => {
      state.activeView = view;
    });
  }

  public setOwnBackground(background: SceneConstructorBackground) {
    this.dispatch(async (state) => {
      state.selectedBackground = background;
      state.baseOrPhotobox = 'base';

      const image = new window.Image();
      image.src = background.base_url;
      image.onload = () => {
        // set image only when it is loaded
        state.configBackground = {
          image: image,
          x: 0,
          y: 0,
          width: ConstructorStageWidth,
          height: ConstructorStageHeight,
          name: 'background',
          scaleX: 1,
          scaleY: 1,
          draggable: true
        };
        state.configConstructedBackground = null;
        state.configFloor = null;
        state.configWall = null;
        state.selectedFloor = null;
        state.selectedWall = null;

        setTimeout(() => {
          state.selectedTransformer = 'background';
        }, 200);
      };
    });
  }

  private async loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise<any>((resolve) => {
      const image = new window.Image();
      image.src = src;
      image.onload = () => {
        resolve(image);
      };
    });
  }

  private async loadImageConfig(
    src: string,
    type: ConstructorTransformTarget
  ): Promise<{
    image: any;
    x: number;
    y: number;
    width: number;
    height: number;
    name: ConstructorTransformTarget;
  }> {
    const image = await this.loadImage(src);

    return {
      image: image,
      x: 0,
      y: 0,
      height: image.height,
      width: image.width,
      name: type
    };
  }

  public setConstructedBackground(wall: RoomComponent, floor: RoomComponent) {
    this.dispatch(async (state) => {
      state.isProcessing = true;
      state.selectedFloor = floor;
      state.selectedWall = wall;

      state.configFloor = await this.loadImageConfig(state.selectedFloor.base_image_url, 'floor');
      state.configWall = await this.loadImageConfig(state.selectedWall.base_image_url, 'wall');

      state.configConstructedBackground = {
        name: 'constructedBackground',
        x: 0,
        y: 0,
        draggable: true,
        width: ConstructorStageWidth,
        height: ConstructorStageHeight,
        scaleX: 1,
        scaleY: 1
      };
      state.configBackground = null;

      state.selectedBackground = {
        base_url: state.selectedWall.base_image_url,
        photo_box_url: state.selectedWall.base_image_url,
        company_id: 0,
        has_room: true,
        id: 0
      };

      state.constructedBackgrounds = [state.selectedBackground, ...state.constructedBackgrounds];

      state.isProcessing = false;

      setTimeout(() => {
        state.selectedTransformer = 'constructedBackground';
      }, 200);
    });
  }

  public setLogo(logo: SceneConstructorLogo) {
    this.dispatch(async (state) => {
      state.selectedLogo = logo;

      const image = new window.Image();
      image.src = logo.url;
      image.onload = () => {
        // set image only when it is loaded
        state.configLogo = {
          image: image,
          x: 0,
          y: 0,
          width: image.width,
          height: image.height,
          name: 'logo',
          scaleX: 1,
          scaleY: 1,
          draggable: true
        };

        setTimeout(() => {
          state.selectedTransformer = 'logo';
        }, 200);
      };
    });
  }

  public setBanner(banner: SceneConstructorBanner) {
    this.dispatch(async (state) => {
      state.selectedBanner = banner;

      const image = new window.Image();
      image.src = banner.url;
      image.onload = () => {
        // set image only when it is loaded
        state.configBanner = {
          image: image,
          x: 0,
          y: 0,
          width: image.width,
          height: image.height,
          name: 'banner',
          scaleX: 1,
          scaleY: 1,
          draggable: true
        };

        setTimeout(() => {
          state.selectedTransformer = 'banner';
        }, 200);
      };
    });
  }
}
