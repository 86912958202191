
import './BgConstructorWalls.css';

import { defineComponent, PropType } from 'vue-demi';
import { useStores } from '@/store/Stores';
import { RoomComponent } from '@/store/modules/RoomComponentsStore';
import { BgConstructorStore, BgConstructorStoreState } from '../BgConstructorStore';

export default defineComponent({
  props: {
    store: Object as PropType<BgConstructorStore>,
    state: Object as PropType<BgConstructorStoreState>
  },
  methods: {
    selectWall(wall: RoomComponent) {
      this.store.dispatch(async (state) => {
        state.selectedWall = wall;
      });
    },
    selectWallAt(wallIndex: number) {
      if (-1 < wallIndex && wallIndex < this.state.walls.length) {
        this.selectWall(this.state.walls[wallIndex]);

        setTimeout(() => {
          document.querySelector('ul.bg-constructor-walls__list').children[wallIndex].scrollIntoView({
            behavior: 'smooth',
            block: 'nearest'
          });
        }, 50);
      }
    },
    selectNextWall() {
      let wallIndex = this.state.walls.indexOf(this.state.selectedWall as any);

      this.selectWallAt(wallIndex + 1);
    },

    selectPreviousWall() {
      let wallIndex = this.state.walls.indexOf(this.state.selectedWall as any);

      this.selectWallAt(wallIndex - 1);
    }
  }
});
