
import './SceneConstructorBgMenu.css';

import API from '../../../../api';
import { defineComponent, PropType } from 'vue-demi';
import {
  ConstructorStageHeight,
  ConstructorStageWidth,
  ConstructorTransformTarget,
  SceneConstructorBackground,
  SceneConstructorStore,
  SceneConstructorStoreState
} from '../SceneConstructorStore';
import SceneConstructorMenu from '../_menu/SceneConstructorMenu/SceneConstructorMenu.vue';
import SceneConstructorMenuItem from '../_menu/SceneConstructorMenuItem/SceneConstructorMenuItem.vue';
import SceneConstructorMenuItemIcon from '../_menu/SceneConstructorMenuItemIcon/SceneConstructorMenuItemIcon.vue';
import { RoomComponent } from '@/store/modules/RoomComponentsStore';
import TabMenu from 'primevue/tabmenu';

export default defineComponent({
  props: {
    store: Object as PropType<SceneConstructorStore>,
    state: Object as PropType<SceneConstructorStoreState>
  },
  methods: {
    createConstructed() {
      const bgConstructor = this.state.bgConstructor();

      bgConstructor.constructAndReturn((args: { wall: RoomComponent; floor: RoomComponent }) => {
        this.store.setConstructedBackground(args.wall, args.floor);
      });
    },
    setBaseOrPhotobox(value: 'base' | 'photo_box') {
      this.store.dispatch(async (state) => {
        state.baseOrPhotobox = value;
      });
    }
  },
  components: { SceneConstructorMenu, SceneConstructorMenuItem, SceneConstructorMenuItemIcon }
});
