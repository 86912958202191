
import './SceneConstructorOwnBgMenu.css';

import { defineComponent, PropType } from 'vue-demi';
import {
  ConstructorStageHeight,
  ConstructorStageWidth,
  SceneConstructorBackground,
  SceneConstructorStore,
  SceneConstructorStoreState
} from '../SceneConstructorStore';
import SceneConstructorMenu from '../_menu/SceneConstructorMenu/SceneConstructorMenu.vue';
import SceneConstructorMenuItem from '../_menu/SceneConstructorMenuItem/SceneConstructorMenuItem.vue';
import SceneConstructorMenuItemIcon from '../_menu/SceneConstructorMenuItemIcon/SceneConstructorMenuItemIcon.vue';
import API from '../../../../api';

export default defineComponent({
  props: {
    store: Object as PropType<SceneConstructorStore>,
    state: Object as PropType<SceneConstructorStoreState>
  },
  methods: {
    async setFile(e: Event) {
      const input = e.target as HTMLInputElement;
      const file = input.files[0];
      if (file) {
        const body = {
          photo: file,
          base_photo: file,
          photo_box_photo: file
        };

        this.store.dispatch(async (state) => (state.isProcessing = true));

        const res = await API.post('company/background', body, 'formData');
        const background: SceneConstructorBackground = res.data;

        this.store.dispatch(async (state) => {
          state.isProcessing = false;
          state.ownBackgrounds = [background, ...state.ownBackgrounds];
          this.store.setOwnBackground(background);
        });
      }
    },
    selectBackground(background: SceneConstructorBackground) {
      this.store.setOwnBackground(background);
      this.store.goTo('bgMenu');
    }
  },
  components: { SceneConstructorMenu, SceneConstructorMenuItem, SceneConstructorMenuItemIcon }
});
