
import './SceneConstructorBgColorMenu.css';

import { defineComponent, PropType } from 'vue-demi';
import ColorPicker from 'primevue/colorpicker';
import { SceneConstructorStore, SceneConstructorStoreState } from '../SceneConstructorStore';
import SceneConstructorMenu from '../_menu/SceneConstructorMenu/SceneConstructorMenu.vue';

export default defineComponent({
  props: {
    store: Object as PropType<SceneConstructorStore>,
    state: Object as PropType<SceneConstructorStoreState>
  },
  data() {
    return {
      backgroundColor: this.state.backgroundColor
    };
  },
  watch: {
    backgroundColor(backgroundColor: string) {
      console.log(backgroundColor);

      this.store.dispatch(async (state) => {
        state.backgroundColor = '#' + backgroundColor;
      });
    }
  },
  components: { ColorPicker, SceneConstructorMenu }
});
