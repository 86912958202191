import { Stores } from '@/store/Stores';
import { RoomComponent } from '../../../store/modules/RoomComponentsStore';

import API from '../../../api';
import { errorHandling } from '@/utils';

export interface BgConstructorStoreState {
  adminMode?: boolean;
  companyId?: number;

  toast: any;
  t: any;

  isLoading: boolean;
  isError: boolean;
  isSaving: boolean;

  isDownloading: boolean;
  isUploading: boolean;

  floors: RoomComponent[];
  walls: RoomComponent[];

  selectedFloor?: RoomComponent;
  selectedWall?: RoomComponent;

  error: any;
}

export class BgConstructorStore {
  public namespace: string;

  constructor(namespace: string) {
    this.namespace = namespace;
  }

  public get state(): BgConstructorStoreState {
    return Stores.rootStore.state[this.namespace];
  }

  public dispatch<T>(doUpdate: (state: BgConstructorStoreState) => Promise<T>): Promise<T> {
    return Stores.rootStore.dispatch(`${this.namespace}/updateState`, { doUpdate });
  }

  public static register(toast, t) {
    const namespace = `bgConstructor_${Date.now()}`;

    Stores.rootStore.registerModule(namespace, {
      namespaced: true,

      state: (): BgConstructorStoreState => ({
        isLoading: true,
        isError: false,
        isSaving: false,

        toast: toast,
        t: t,

        isDownloading: false,
        isUploading: false,

        walls: [],
        floors: [],
        error: null as any
      }),

      actions: {
        async updateState({ state }: { state: BgConstructorStoreState }, args: { doUpdate: (state: BgConstructorStoreState) => Promise<any> }) {
          return await args.doUpdate(state);
        }
      }
    });

    return new BgConstructorStore(namespace);
  }

  public unregister() {
    Stores.rootStore.unregisterModule(this.namespace);
  }

  private async refresh(state: BgConstructorStoreState): Promise<void> {
    if (state.adminMode) {
      const roomResponse: any = await API.get(`admin/wall`, { company: state.companyId });
      const floorResponse = await API.get(`admin/floor`, { company: state.companyId });

      state.walls = roomResponse.data.data;
      state.floors = floorResponse.data.data;
    } else {
      let response: any = await API.get('company/room/components');

      state.walls = response.data.walls;
      state.floors = response.data.floors;
    }

    state.selectedWall = state.selectedWall ? state.walls.filter((s) => s.id == state.selectedWall.id)[0] : (null as any);

    state.selectedFloor = state.selectedFloor ? state.floors.filter((s) => s.id == state.selectedFloor.id)[0] : (null as any);

    if (!state.selectedWall) {
      state.selectedWall = state.walls && state.walls.length ? state.walls[0] : (null as any);
    }

    if (!state.selectedFloor) {
      state.selectedFloor = state.floors && state.floors.length ? state.floors[0] : (null as any);
    }
  }

  public async init(adminMode: boolean = false, companyId: number = null): Promise<void> {
    this.dispatch(async (state) => {
      state.isLoading = true;

      state.adminMode = adminMode;
      state.companyId = companyId;

      state.isLoading = true;

      try {
        await this.refresh(state);

        state.isLoading = false;
        state.isError = false;
      } catch (error) {
        state.isLoading = false;
        state.isError = true;
        state.error = error;
      }
    });
  }

  public async downloadWallOrFloorImages(target: 'wall' | 'floor') {
    this.dispatch(async (state) => {
      try {
        state.isDownloading = true;

        const targetId = target == 'wall' ? state.selectedWall.id : state.selectedFloor.id;

        let response: any = state.adminMode
          ? await API.get(`room/${target}/${targetId}/download`)
          : await API.get(`company/room/${target}/${targetId}/download`);

        const url = response.data.link;

        const a = document.createElement('a');
        a.href = url;
        a.download = url.split('/').pop();
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        state.isDownloading = false;
      } catch (error) {
        state.isError = true;
      }
    });
  }

  public async uploadWallOrFloorImages(target: 'wall' | 'floor', name: string, files: File[]) {
    this.dispatch(async (state) => {
      try {
        state.isUploading = true;

        const targetId = target == 'wall' ? state.selectedWall.id : state.selectedFloor.id;

        const requestParams: any = {
          name: name
        };

        for (let i = 0; i < files.length; i++) {
          requestParams[`image[angle_${i + 1}]`] = files[i];
        }

        if (state.adminMode) {
          // requestParams.company = state.companyId;
          await API.post2(`admin/${target}?company=` + state.companyId, requestParams);
        } else {
          await API.post2(`company/room/${target}`, requestParams);
        }

        await this.refresh(state);

        state.isUploading = false;
      } catch (error) {
        state.isUploading = false;
        state.isError = true;

        const { title, message } = errorHandling(error, state.t);
        state.toast.add({ severity: 'error', summary: title, detail: message, life: 3000 });
      }
    });
  }
}
