
import './SceneConstructorConstructedBgMenu.css';

import { defineComponent, PropType } from 'vue-demi';
import API from '../../../../api';
import { SceneConstructorBackground, SceneConstructorStore, SceneConstructorStoreState } from '../SceneConstructorStore';
import SceneConstructorMenu from '../_menu/SceneConstructorMenu/SceneConstructorMenu.vue';
import SceneConstructorMenuItem from '../_menu/SceneConstructorMenuItem/SceneConstructorMenuItem.vue';
import SceneConstructorMenuItemIcon from '../_menu/SceneConstructorMenuItemIcon/SceneConstructorMenuItemIcon.vue';
import { RoomComponent } from '@/store/modules/RoomComponentsStore';

export default defineComponent({
  props: {
    store: Object as PropType<SceneConstructorStore>,
    state: Object as PropType<SceneConstructorStoreState>
  },
  methods: {
    createConstructed() {},
    selectBackground(background: SceneConstructorBackground) {
      //this.store.setBackground(background);
      this.store.goTo('bgMenu');
    }
  },
  components: { SceneConstructorMenu, SceneConstructorMenuItem, SceneConstructorMenuItemIcon }
});
