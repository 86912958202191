
import './BgConstructor.css';

import Button from 'primevue/button';
import { Icon } from '../../../components/ui';
import Dialog from 'primevue/dialog';
import { defineComponent } from 'vue-demi';
import { Stores, useStores } from '@/store/Stores';
import { computed } from 'vue';
import Loader from '@/components/ui/Loader.vue';
import BgConstructorFloors from './BgConstructorFloors/BgConstructorFloors.vue';
import BgConstructorWalls from './BgConstructorWalls/BgConstructorWalls.vue';
import BgConstructorPreview from './BgConstructorPreview/BgConstructorPreview.vue';
import { RoomComponent } from '@/store/modules/RoomComponentsStore';
import { BgConstructorStore, BgConstructorStoreState } from './BgConstructorStore';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    isShown: Boolean,

    adminMode: Boolean,
    companyId: Number
  },
  data() {
    return {
      callback: null as (args: { wall: RoomComponent; floor: RoomComponent }) => any,

      store: null as BgConstructorStore,
      state: null as BgConstructorStoreState
    };
  },
  emits: ['update:isShown'],
  watch: {
    isShown(isShown: boolean, isShown_old: boolean) {
      if (isShown == isShown_old) {
        return;
      }

      if (isShown) {
        const store = BgConstructorStore.register(this.toast, this.t);
        const state = store.state;

        store.init(this.adminMode, this.companyId);

        this.store = store;
        this.state = state;
      } else {
        this.store.unregister();

        this.store = null;
        this.state = null;
      }
    }
  },
  components: {
    Dialog,
    Loader,
    Button,
    Icon,
    BgConstructorFloors,
    BgConstructorWalls,
    BgConstructorPreview
  },
  methods: {
    constructAndReturn(callback: (args: { wall: RoomComponent; floor: RoomComponent }) => void) {
      this.callback = callback;
      this.$emit('update:isShown', true);
    },
    onSave() {
      if (this.callback) {
        this.callback({ floor: this.state.selectedFloor, wall: this.state.selectedWall });
        this.$emit('update:isShown');
      } else {
        this.$emit('update:isShown');
      }
    }
  },
  setup() {
    const toast = useToast();
    const { t } = useI18n();

    return {
      toast,
      t
    };
  }
});
