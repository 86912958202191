
import './SceneConstructorMenuItemIcon.css';

import { defineComponent, PropType } from 'vue-demi';

export default defineComponent({
  props: {
    backgroundColor: String,
    backgroundImage: String,
    checked: Boolean
  }
});
