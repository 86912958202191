
import './SceneConstructor.css';

import Button from 'primevue/button';
import { Icon } from '../../../components/ui';
import Dialog from 'primevue/dialog';
import { defineComponent, PropType } from 'vue-demi';
import Loader from '@/components/ui/Loader.vue';

import { SceneConstructorStore, SceneConstructorStoreState } from './SceneConstructorStore';

import SceneConstructorContents from './SceneConstructorContents/SceneConstructorContents.vue';

import SceneConstructorStartMenu from './SceneConstructorStartMenu/SceneConstructorStartMenu.vue';
import SceneConstructorBgMenu from './SceneConstructorBgMenu/SceneConstructorBgMenu.vue';
import SceneConstructorBgColorMenu from './SceneConstructorBgColorMenu/SceneConstructorBgColorMenu.vue';
import SceneConstructorOwnBgMenu from './SceneConstructorOwnBgMenu/SceneConstructorOwnBgMenu.vue';
import SceneConstructorConstructedBgMenu from './SceneConstructorConstructedBgMenu/SceneConstructorConstructedBgMenu.vue';
import SceneConstructorLogoMenu from './SceneConstructorLogoMenu/SceneConstructorLogoMenu.vue';
import SceneConstructorBannerMenu from './SceneConstructorBannerMenu/SceneConstructorBannerMenu.vue';

export default defineComponent({
  props: {
    isShown: Boolean,

    bgConstructor: Function as PropType<() => any>,

    adminMode: Boolean,
    companyId: Number
  },
  emits: ['update:isShown'],
  data() {
    return {
      store: null as SceneConstructorStore,
      state: null as SceneConstructorStoreState
    };
  },
  watch: {
    isShown(isShown: boolean, isShown_old: boolean) {
      if (isShown == isShown_old) {
        return;
      }

      if (isShown) {
        const store = SceneConstructorStore.register();
        const state = store.state;

        store.init(this.adminMode, this.companyId, this.bgConstructor);

        this.store = store;
        this.state = state;
      } else {
        this.store.unregister();

        this.store = null;
        this.state = null;
      }
    }
  },
  methods: {
    close() {
      this.$emit('update:isShown');
    }
  },
  components: {
    Dialog,
    Loader,
    Button,
    Icon,
    SceneConstructorStartMenu,
    SceneConstructorBgMenu,
    SceneConstructorContents,
    SceneConstructorBgColorMenu,
    SceneConstructorOwnBgMenu,
    SceneConstructorConstructedBgMenu,
    SceneConstructorLogoMenu,
    SceneConstructorBannerMenu
  },
  unmounted() {
    if (this.store) {
      this.store.unregister();
    }
  }
});
