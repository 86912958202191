
import './SceneConstructorMenuItem.css';

import { defineComponent } from 'vue-demi';

export default defineComponent({
  props: {
    text: String
  }
});
