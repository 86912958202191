import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "bg-constructor-preview" }
const _hoisted_2 = {
  key: 0,
  class: "bg-constructor-preview__item bg-constructor-preview__floor"
}
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "bg-constructor-preview__item bg-constructor-preview__wall"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "bg-constructor-preview__buttons" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BgConstructorImportExport = _resolveComponent("BgConstructorImportExport")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.state.selectedFloor)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.state.selectedFloor.base_image_url
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    (_ctx.state.selectedWall)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.state.selectedWall.base_image_url
          }, null, 8, _hoisted_5)
        ]))
      : _createCommentVNode("", true),
    (_ctx.state.adminMode)
      ? (_openBlock(), _createBlock(_component_BgConstructorImportExport, {
          key: 2,
          store: _ctx.store,
          state: _ctx.state
        }, null, 8, ["store", "state"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_Button, {
        onClick: _ctx.createRoom,
        class: "p-button p-component",
        disabled: _ctx.state.isSaving
      }, {
        default: _withCtx(() => [
          (!_ctx.state.isSaving)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t('bg_constructor.save')), 1))
            : _createCommentVNode("", true),
          (_ctx.state.isSaving)
            ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('bg_constructor.saving')), 1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onClick", "disabled"]),
      _createVNode(_component_Button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
        class: "p-button p-component p-button-raised p-button-secondary p-button-text"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('bg_constructor.cancel')), 1)
        ]),
        _: 1
      })
    ])
  ]))
}