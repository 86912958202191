
import './SceneConstructorStartMenu.css';

import { defineComponent, PropType } from 'vue-demi';
import { SceneConstructorStore, SceneConstructorStoreState } from '../SceneConstructorStore';
import SceneConstructorMenu from '../_menu/SceneConstructorMenu/SceneConstructorMenu.vue';
import SceneConstructorMenuItem from '../_menu/SceneConstructorMenuItem/SceneConstructorMenuItem.vue';
import SceneConstructorMenuItemIcon from '../_menu/SceneConstructorMenuItemIcon/SceneConstructorMenuItemIcon.vue';

export default defineComponent({
  props: {
    store: Object as PropType<SceneConstructorStore>,
    state: Object as PropType<SceneConstructorStoreState>
  },
  methods: {},
  components: { SceneConstructorMenu, SceneConstructorMenuItem, SceneConstructorMenuItemIcon }
});
