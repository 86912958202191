
import API from '../../../../api';
import './SceneConstructorContents.css';

import { defineComponent, PropType } from 'vue-demi';
import {
  ConstructorStageHeight,
  ConstructorStageWidth,
  ConstructorTransformTarget,
  SceneConstructorStore,
  SceneConstructorStoreState
} from '../SceneConstructorStore';
import { useStores } from '@/store/Stores';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  props: {
    store: Object as PropType<SceneConstructorStore>,
    state: Object as PropType<SceneConstructorStoreState>
  },
  data() {
    return {
      refreshSizeInterval: 0
    };
  },
  watch: {
    'state.selectedTransformer'() {
      this.updateTransformer();
    }
  },
  methods: {
    refreshSize() {
      const contentsWrap: HTMLDivElement = this.$refs.contentsWrap as any;
      const contentsIn: HTMLDivElement = this.$refs.contentsIn as any;

      if (!contentsWrap || !contentsIn) {
        return;
      }

      const containerHeight = contentsWrap.offsetHeight;
      const containerWidth = contentsWrap.offsetWidth;

      const aspectRatio = ConstructorStageHeight / ConstructorStageWidth;

      let stageHeight = ConstructorStageHeight;
      let stageWidth = ConstructorStageWidth;

      if (stageHeight > containerHeight) {
        stageHeight = containerHeight;
        stageWidth = containerHeight / aspectRatio;
      }

      if (stageWidth > containerWidth) {
        stageWidth = containerWidth;
        stageHeight = containerWidth * aspectRatio;
      }

      if (this.state.configKonva.width != stageWidth || this.state.configKonva.height != stageHeight) {
        this.store.dispatch(async (state) => {
          state.configKonva.width = stageWidth;
          state.configKonva.height = stageHeight;
          state.configKonva.scaleX = stageWidth / ConstructorStageWidth;
          state.configKonva.scaleY = stageHeight / ConstructorStageHeight;
        });
      }
    },
    onMouseDown(e) {
      const targetNode = e.target as any;

      if (targetNode.nodeName != 'CANVAS') {
        this.store.dispatch(async (state) => (state.selectedTransformer = 'none'));
      }
    },
    handleStageMouseDown(e) {
      // clicked on stage - clear selection
      if (e.target === e.target.getStage()) {
        this.store.dispatch(async (state) => (state.selectedTransformer = 'none'));
        return;
      }

      // clicked on transformer - do nothing
      const clickedOnTransformer = e.target.getParent().className === 'Transformer';
      if (clickedOnTransformer) {
        return;
      }

      const targetName: ConstructorTransformTarget = e.target.name();

      if (targetName == 'wall' || targetName == 'floor') {
        this.store.dispatch(async (state) => (state.selectedTransformer = 'constructedBackground'));
      } else {
        this.store.dispatch(async (state) => (state.selectedTransformer = e.target.name()));
      }
    },
    updateTransformer() {
      // here we need to manually attach or detach Transformer node
      const transformerNode = (this.$refs.transformer as any).getNode();
      const stage = transformerNode.getStage();

      const selectedTarget = this.state.selectedTransformer == 'none' ? '' : this.state.selectedTransformer;

      transformerNode.rotateEnabled(false);

      const selectedNode = stage.findOne('.' + selectedTarget);
      // do nothing if selected node is already attached
      if (selectedNode === transformerNode.node()) {
        return;
      }

      if (selectedNode) {
        // attach to another node
        transformerNode.nodes([selectedNode]);
      } else {
        // remove transformer
        transformerNode.nodes([]);
      }
    },
    getElementAttrs(targetEl: any): { x: number; y: number; width: number; height: number } {
      if (!targetEl) {
        return null;
      }

      const attrs: { x: number; y: number; scaleX: number; scaleY: number; width: number; height: number } = targetEl.getNode().attrs;

      const x = Math.round(attrs.x);
      const y = Math.round(attrs.y);
      const width = Math.round(attrs.width * attrs.scaleX);
      const height = Math.round(attrs.height * attrs.scaleY);

      return {
        x,
        y,
        width,
        height
      };
    },
    saveChanges() {
      this.store.dispatch(async (state) => {
        if (!state.selectedBackground) {
          return;
        }

        state.isSaving = true;

        let params: any = {
          zero_layer_color: state.backgroundColor
        };

        if (state.selectedBackground && !state.selectedBackground.has_room) {
          const backgroundImageAttrs = this.getElementAttrs(this.$refs.backgroundImage);

          params = {
            ...params,

            background_id: state.selectedBackground.id,

            background_width: backgroundImageAttrs.width,
            background_height: backgroundImageAttrs.height,
            background_offset_x: backgroundImageAttrs.x,
            background_offset_y: backgroundImageAttrs.y
          };
        } else {
          const constructedBackgroundImageAttrs = this.getElementAttrs(this.$refs.constructedBackgroundImage);

          params = {
            ...params,

            floor_id: state.selectedFloor.id,
            wall_id: state.selectedWall.id,

            background_width: constructedBackgroundImageAttrs.width,
            background_height: constructedBackgroundImageAttrs.height,
            background_offset_x: constructedBackgroundImageAttrs.x,
            background_offset_y: constructedBackgroundImageAttrs.y
          };
        }

        if (state.selectedLogo) {
          const logoImageAttrs = this.getElementAttrs(this.$refs.logoImage);

          params = {
            ...params,

            logo_id: state.selectedLogo.id,

            logo_width: logoImageAttrs.width,
            logo_height: logoImageAttrs.height,
            logo_offset_x: logoImageAttrs.x,
            logo_offset_y: logoImageAttrs.y
          };
        }

        if (state.selectedBanner) {
          const bannerImageAttrs = this.getElementAttrs(this.$refs.bannerImage);

          params = {
            ...params,

            banner_id: state.selectedBanner.id,

            banner_width: bannerImageAttrs.width,
            banner_height: bannerImageAttrs.height,
            banner_offset_x: bannerImageAttrs.x,
            banner_offset_y: bannerImageAttrs.y
          };
        }

        try {
          if (state.adminMode) {
            await API.post(`admin/companies/${state.companyId}/room`, params);
          } else {
            await API.post('company/room', params);
          }
        } catch (error) {
          let keys: string[] = Object.keys(error.response.data.errors);
          let errorMessage: string = '';

          for (let key of keys) {
            if (errorMessage != '') {
              errorMessage += ' ';
            }

            for (let errorItem of error.response.data.errors[key]) {
              if (errorMessage != '') {
                errorMessage += ' ';
              }

              errorMessage += errorItem;
            }
          }

          this.toast.add({ severity: 'error', summary: this.t('error'), detail: errorMessage, life: 3000 });

          console.log('Error saving scene: ', {
            errorMessage: errorMessage,
            params: params
          });

          state.isSaving = false;

          return;
        }

        console.log('Scene saved: ', {
          params: params
        });

        if (state.adminMode) {
          await this.adminCarStore.initRooms(state.companyId);
        } else {
          await this.roomComponentsStore.init();
        }

        state.isSaving = false;

        this.$emit('close');
      });
    }
  },
  mounted() {
    this.refreshSizeInterval = setInterval(() => {
      this.refreshSize();
    }, 100);

    setTimeout(() => {
      this.refreshSize();
    }, 100);

    window.addEventListener('mousedown', this.onMouseDown);
    window.addEventListener('resize', this.refreshSize);
  },
  unmounted() {
    this.store.unregister();

    window.removeEventListener('mousedown', this.onMouseDown);
    window.removeEventListener('resize', this.refreshSize);

    clearInterval(this.refreshSizeInterval);
  },
  setup() {
    const { roomComponentsStore, roomComponentsStoreState, adminCarStoreState, adminCarStore } = useStores();

    const toast = useToast();
    const { t } = useI18n();

    return {
      roomComponentsStore,
      roomComponentsStoreState,
      adminCarStoreState,
      adminCarStore,

      toast,
      t
    };
  }
});
